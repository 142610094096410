import LoanHistoryCardTable from "components/Table";
import React, { useRef } from 'react'
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import EmiTable from "./EmiTable";
import { useAlert } from "react-alert";
import IntrestBasisTable from "views/admin/intrestBasisTable";
import StructuredEmiTable from "views/admin/StructuredEmiTable";
import moment from "moment";
import { useEffect } from "react";
import XIRR from "actions/xirr";
import { saveVariableEmi } from "actions/variableEmiActions";
import { saveVariableEmiHeader } from "actions/variableEmiActions";
import { clearData } from "actions/variableEmiActions";

// components

export default function CardSettings2() {

    const { variableEmi } = useSelector(state => state.variableEmi);
    const { variableHeaderData } = useSelector(state => state.variableHeader);
    let noOfDaysArr = []

    console.log(variableEmi);
    console.log(variableHeaderData);
    let difff = []
    let dates = []
    let values = []
    let resultArray = []

    console.log("variableEmi", variableEmi);
    console.log("variableHeaderData", variableHeaderData);
    const [count, setCount] = useState(0);
    const [showEmi, setShowEmi] = useState(false)
    const [frequency, setFrequency] = useState("");
    const [loanAmount, setLoanAmount] = useState('')
    const [emiAmount, setEmiAmount] = useState(null);
    const [flatIntrest, setFlatIntrest] = useState('')
    const [tenure, setTenure] = useState('')
    const [actualIntrestRate, setActualIntrestRate] = useState('')
    const [intrestStartDate, setIntrestStartDate] = useState('')
    const [firstEmiDate, setFirstEmiDate] = useState('')

    const [installmentNo, setInstallmentNo] = useState('')
    const alert = useAlert()
    const dispatch = useDispatch()
    const history = useHistory()
    const color = "light"
    
    const numberOfInstallments = Math.ceil(tenure / frequency)
    const emiArray = []

    console.log(emiArray)
    const emiValues = () => {



    }


    const handleTenure = (e) => {
        setTenure(e.target.value)

    }

    if (tenure) {
        for (let i = 0; i < tenure; i++) {
            let obj = {}
            obj.emiNos = i + 1
            obj.emiDate = "05-Apr-2019"
            obj.emiAmount = 1000
            emiArray.push(obj)
        }

    }
    const handleProceed = (frequency,loanAmount,tenure,intrestStartDate,firstEmiDate) => {

        if (
            (frequency === "" ||
                loanAmount === "" ||
                tenure === "" ||

                intrestStartDate === ""

                ||
                firstEmiDate === ""

            )
        ) {
            alert.error("Please fill all the fields");
        }
        else {
            setShowEmi(true)
        }


    }
    const getDenm = () => {
        switch (frequency) {
            case "1":
                return 12;
            case "2":
                return 6;
            case "3":
                return 4;
            case "6":
                return 2;

        }
    }
    const denm = getDenm(frequency);
    console.log(showEmi)
    let momentIntrestStartDate =
        moment(intrestStartDate).format("DD-MM-YYYY");
    let momentStartDateTemp = moment(momentIntrestStartDate, "DD-MM-yyyy");

    let dateOfFirstEmi = moment(firstEmiDate).format("DD-MM-yyyy")
    console.log("firstEmiDatess", moment(firstEmiDate).format("MM-DD-YYYY"))
    let firstEmiDatee;
    //console.log(momentStartDateTemp)
    //console.log(momentStartDateTemp.date());
    if (momentStartDateTemp.date() < 21) {
        // newDate = moment(momentIntrestStartDate).add(1, "M");
        firstEmiDatee = momentStartDateTemp.add(frequency, "M");
        firstEmiDatee.set("date", 5);
        console.log("firstEmiDate", firstEmiDatee.format("DD-MM-YYYY"));
    } else {
        var m = parseInt(frequency) + 1;
        console.log(typeof m);
        firstEmiDatee = momentStartDateTemp.add(m, "M");
        firstEmiDatee.set("date", 5);
        console.log("firstEmiDate", firstEmiDatee.format("DD-MM-YYYY"));
    }
    let emiDateOne = dateOfFirstEmi
    dates.push(moment(dateOfFirstEmi, "DD-MM-yyyy").format("MM-DD-YYYY"));
    //console.log("emiDateOne",emiDateOne);
    let diffFirst = moment(emiDateOne, "DD-MM-yyyy").diff(
        moment(momentIntrestStartDate, "DD-MM-yyyy"),
        "days"
    );
    noOfDaysArr.push(diffFirst);
    for (var i = 0; i < numberOfInstallments - 1; i++) {
        let date2 = moment(dateOfFirstEmi, "DD-MM-yyyy")
            .add(frequency, "M")
            .format("DD-MM-YYYY");
        console.log("date2", date2)

        //console.log(roiPer)
        let diff = moment(date2, "DD-MM-yyyy").diff(
            moment(dateOfFirstEmi, "DD-MM-yyyy"),
            "days"
        );
        difff.push(diff)
        noOfDaysArr.push(diff);
        //console.log("discountingFactor",discountingFactor)
        date2 = moment(date2, "DD-MM-yyyy")
        date2.set("date", 5);
        date2 = date2.format("MM-DD-YYYY");
        console.log(date2)
        dates.push(date2)
        dateOfFirstEmi = moment(date2, "MM-DD-yyyy").format("DD-MM-YYYY");

    }
    console.log(dates)

    const handleAmountChange = ev => {
        const idx = ev.target.id;       // to access specific array element
        const val = ev.target.value;    // to access the date-value selected by user
        setEmiAmount(prev => {            // "prev" is the current array
            const nd = [...prev];         // shallow-copy "prev" into "nd" (new-data) array
            nd[idx].emiAmount = parseInt(val);
            values.push(nd[idx].emiAmount)


            // update the "tenureDate" at position "idx"
            return nd;                    // return the updated "nd" array to store into state
        });
    };
    const handleAmountClear =()  => {
        dispatch(clearData())
        setShowEmi(false)
       setEmiAmount("")
    };

    useEffect(() => setEmiAmount(
        (numberOfInstallments && numberOfInstallments > 0)
            ? ([...Array(+numberOfInstallments).keys()].map(
                id => ({ id, emiAmount: '' })
            )) : null
    ), [numberOfInstallments,showEmi]);
    console.log(emiAmount)
    emiAmount && emiAmount.map((emi, idx) => {

        values.push(emi.emiAmount)
    })
    values.unshift(loanAmount * (-1))
    const startArray = momentIntrestStartDate.split("-");
    const year = startArray[2];
    const month = startArray[1];
    const day = startArray[0];
    const startDate = month + "-" + day + "-" + year;
    let array3 = []
    dates.unshift(startDate)
    // dates.forEach((x, i) => i===0 ? x : moment(x, "DD-MM-yyyy").format("MM-DD-YYYY"));
    console.log(values)
    console.log(dates)
    const xirr = XIRR(values, dates)
    console.log("xirr", xirr)
    console.log("xirr", xirr)
    let nominal = Math.pow(1 + xirr, 1 / denm) - 1;
    nominal = nominal * denm
    const actualNominal = nominal
    nominal = nominal.toFixed(5)
    console.log("nominal", nominal)
    console.log("demn", denm)
    console.log(diffFirst)
    console.log(loanAmount, typeof (loanAmount))
    console.log(loanAmount - loanAmount)
    console.log(loanAmount, typeof (loanAmount))


    let intrestComponent1 =
        loanAmount * (nominal) * (diffFirst / 365);
    intrestComponent1 = intrestComponent1.toFixed(2)
    console.log("intrestComponent1", intrestComponent1)
    let openingPrincipal1 = parseInt(loanAmount);

    let firstEmi = parseInt(values[1])
    console.log("firstEmi", firstEmi)
    let principalComponent1 = firstEmi - intrestComponent1;
    principalComponent1 = principalComponent1.toFixed(2)
    console.log("principalComponent1", principalComponent1)
    let closingPrincipal1 = openingPrincipal1 - principalComponent1;
    closingPrincipal1 = closingPrincipal1.toFixed(2)
    console.log("difff", difff)
    console.log()
    let obj1 = {}
    obj1.installmentNo = 1
    obj1.openingPrincipal = openingPrincipal1
    obj1.dueDate = dates[1]
    obj1.noOfDays = diffFirst
    obj1.installmentAmount = firstEmi
    obj1.openingPrincipal = openingPrincipal1
    obj1.intrestComponent = intrestComponent1
    obj1.principalComponent = principalComponent1
    obj1.closingPrincipal = closingPrincipal1
    resultArray.push(obj1)
    console.log("resultArray", resultArray)
    console.log(numberOfInstallments)
    for (var i = 1; i < numberOfInstallments; i++) {
        let obj = {}
        let closingPrincipal11 = parseInt(closingPrincipal1)
        let intrestComponent = closingPrincipal11 * (nominal) * (difff[i - 1] / 365)
        intrestComponent = intrestComponent.toFixed(2)
        let principalComponent = values[i + 1] - intrestComponent
        principalComponent = principalComponent.toFixed(2)
        let openingPrincipal = closingPrincipal1
        let closingPrincipal = openingPrincipal - principalComponent
        closingPrincipal = closingPrincipal.toFixed(2)
        if (i == numberOfInstallments - 1) {
            if (closingPrincipal != 0) {
                principalComponent = parseFloat(principalComponent) + parseFloat(closingPrincipal);
                principalComponent = principalComponent.toFixed(2)
                intrestComponent = intrestComponent - closingPrincipal;
                intrestComponent = intrestComponent.toFixed(2)
                closingPrincipal = 0;
            }
        }





        obj.installmentNo = i + 1
        obj.openingPrincipal = loanAmount
        obj.dueDate = dates[i + 1]
        obj.noOfDays = difff[i - 1]
        obj.installmentAmount = values[i + 1]
        obj.openingPrincipal = openingPrincipal
        obj.intrestComponent = intrestComponent
        obj.principalComponent = principalComponent
        obj.closingPrincipal = closingPrincipal
        closingPrincipal1 = closingPrincipal
        resultArray.push(obj)
        obj = {}
       
    }

    console.log("dateOfFirstEmi", dateOfFirstEmi)
    console.log("resultArray", resultArray)
    console.log(array3)
    let totalIntrest = 0
    resultArray.map((x, i) => {
        totalIntrest += parseInt(x.intrestComponent)
    })
    console.log("totalIntrest", totalIntrest)

    totalIntrest = totalIntrest.toFixed(2)
    let flatRate = (totalIntrest / loanAmount) * 100;
    flatRate = (flatRate / numberOfInstallments) * denm

    const headerDataVariableEmis = {
        frequencyy: frequency,
        numberofInstallments: numberOfInstallments,
        loanAmountt: loanAmount,
        xirr: (xirr * 100).toFixed(2),
        flatRate: flatRate,
        actualNominal: actualNominal,
        nominal: (nominal * 100).toFixed(2),
    }




    resultArray.map((x, i) => {
        x.noOfDays = noOfDaysArr[i]
    })
   
    let totalEmi = 0
    if (emiAmount) {
        emiAmount.map((x, i) => {
            console.log((x.emiAmount))
            totalEmi += x.emiAmount
        })
    }


    console.log(emiAmount)
    let toalEmi = 0

    console.log("totalEmi", toalEmi)
    let datesArr = []
    resultArray.map((x, i) => {
        datesArr.push(x.dueDate)
    })
    const handleEmiPattern = () => {
        if (emiAmount) {
            emiAmount.map((x, i) => {
                toalEmi += x.emiAmount
            })
        }
        if (toalEmi < loanAmount) {
            alert.error("total emi is less than loan amount")
        }
        else {
            dispatch(saveVariableEmi(resultArray))
            dispatch(saveVariableEmiHeader(headerDataVariableEmis))
        }


    }

    const clear = () => {
        dispatch(clearData())
        setLoanAmount("")
        setFrequency("")
        setTenure("")
        setIntrestStartDate("")
        setFirstEmiDate("")
       setShowEmi(false)
   


    }
    console.log("loanAmount",loanAmount)
    return (
        <>
            <div className="flex py-2 h-24 w-full flex-row flex-wrap">

                <div className="w-full lg:w-6/12 px-4">
                    <div className="flex flex:row">
                        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">


                            <div className="flex-auto px-4 lg:px-10 py-10 pt-0">

                               
                                    <div className="w-full py-4 lg:w-6/12 px-4">
                                        <label
                                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            frequency
                                        </label>
                                        <select value={frequency} onChange={(e) => setFrequency(e.target.value)} className={"border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"} aria-label="Default select example">
                                            <option  className="text-base border-0 outline-none capitalize bg-white text-black " >
                                                Choose from below
                                            </option>
                                            <option value={1} className="text-base border-0 outline-none capitalize bg-white text-black " >
                                                Monthly
                                            </option>

                                            <option value={3} className="text-base border-0 outline-none capitalize bg-white text-black " >
                                                Quaterly
                                            </option>
                                            <option value={6} className="text-base border-0 outline-none capitalize bg-white text-black " >
                                                Half Yearly
                                            </option>


                                        </select>

                                    </div>

                                    <div className="flex py-4 flex-wrap">
                                        <div className="w-full lg:w-6/12 px-4">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    Loan Amount
                                                </label>
                                                <input
                                                    onChange={(e) => setLoanAmount(e.target.value)}
                                                    value={loanAmount}
                                                    placeholder="100000"
                                                    type="number"
                                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                />
                                            </div>
                                        </div>
                                    </div>



                                    <div className="w-full lg:w-6/12 px-4">
                                        <div className="relative w-full mb-3">
                                            <label
                                                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                htmlFor="grid-password"
                                            >
                                                Tenure (in months)
                                            </label>
                                            <input
                                                onChange={(e) => handleTenure(e)}
                                                value={tenure}
                                                type="number"
                                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                placeholder="5"
                                            />
                                        </div>
                                    </div>

                                    <div className="w-full lg:w-6/12 px-4">
                                        <div className="relative w-full mb-3">
                                            <label
                                                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                htmlFor="grid-password"
                                            >
                                                Interest start Date
                                            </label>
                                            <input
                                                onChange={(e) => setIntrestStartDate(e.target.value)}
                                                type="date"
                                                value={intrestStartDate}
                                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                placeholder="18"
                                            />
                                        </div>
                                        <div className="relative w-full mb-3">
                                            <label
                                                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                htmlFor="grid-password"
                                            >
                                                First Emi Date
                                            </label>
                                            <input
                                                onChange={(e) => setFirstEmiDate(e.target.value)}
                                                type="date"
                                                value={firstEmiDate}
                                                min={intrestStartDate}
                                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                placeholder="18"
                                            />
                                        </div>
                                    </div>






                                <button onClick={clear} className=" px-4  bg-clear-500 mt-2  text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 rounded-full">
                                    Clear
                                </button>

                                <button onClick={handleProceed} className=" mt-2 bg-emi-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 rounded-full">
                                    Enter Emis
                                </button>

                            </div>

                        </div>
                    </div>
                </div>
                {/*
                    emiArray.length > 0 && intrestStartDate &&
                    <EmiTable loanAmount={loanAmount} tenure={tenure} frequency={frequency} emiArray={emiArray} intrestStartDate={intrestStartDate} firstEmiDate={firstEmiDate}/>
                }
                {
                    variableEmi.length &&
                    <StructuredEmiTable id="result" headerData={variableHeaderData} dataa={variableEmi} />
                */ }

                {
                    showEmi &&
                    <div className="relative flex h-64 flex-col lg:w-6/12 min-w-0 break-words w-full mb-6  shadow-lg rounded-lg bg-blueGray-100 border-0">

                        <div className="flex-auto flex:col px-4 lg:px-10 py-0 pt-0">
                            <form>
                                <div className="w-full  py-2  px-2">


                                    {emiAmount &&
                                        Array.isArray(emiAmount) &&
                                        emiAmount.length > 0 &&
                                        emiAmount.map(
                                            ({ id, loan }) => (
                                                <div className="flex w-ful flex-row py-1">



                                                    <div className="relative w-full">

                                                        <label
                                                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                            htmlFor="grid-password"
                                                        >

                                                        </label>
                                                        <p
                                                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                        >{moment(datesArr[id]).format("DD-MM-YYYY")}</p>
                                                    </div>
                                                    <div className="relative  px-2 w-full">
                                                        <label
                                                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                                            htmlFor="grid-password"
                                                        >
                                                        </label>
                                                        <input
                                                            onChange={handleAmountChange}
                                                            placeholder={`Enter EMI ${id + 1}`}
                                                            key={id} id={id} type="number"
                                                            required={true}
                                                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                        />
                                                    </div>
                                                </div>


                                            )
                                        )}

                                </div>


                            </form>
                            <button
                                onClick={handleAmountClear}
                                className=" px-4   bg-clear-500   text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 rounded-full"
                            >
                                Clear
                            </button>

                            <button onClick={handleEmiPattern}
                                className="bg-emi-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 rounded-full"
                            >
                                Emi pattern
                            </button>
                        </div>
                    </div>
                }



            </div>
        </>

    );
}