import moment from "moment";
import { xirr, CashFlow } from "@webcarrot/xirr";
import { SAVE_UNSTRUCTURED_EMI } from "constants/formConstants";
import { GET_STRUCTURED_EMI_FAILURE } from "constants/formConstants";
import XIRR from "./xirr";
import { SAVE_HEADER_DATA } from "constants/formConstants";
import { cleardata } from "./formActionEmiBasis";
import { GET_DATA_SUCCESS } from "constants/formConstants";
import { CLEAR_DATA } from "constants/formConstants";

export const intrestBasisAction =
  (loanAmount, frequency, intrestRate, tenure, intrestStartDate) =>
  async (dispatch) => {
    localStorage.clear("emiBasisResult");

    const getDenm = () => {
      switch (frequency) {
        case "1":
          return 12;
        case "2":
          return 6;
        case "3":
          return 4;
        case "6":
          return 2;
      }
    };
    let noOfdaysArr = [];
    const denm = getDenm(frequency);
    console.log("demn", denm);
    const noOfInstallments = Math.ceil(tenure / frequency);
    const momentIntrestStartDate =
      moment(intrestStartDate).format("DD-MM-YYYY");
    console.log("tenure", tenure);
    console.log("frequency", frequency);
    console.log(tenure / frequency);
    console.log(
      "intrestStartDate",
      moment(intrestStartDate).format("YYYY-MM-DD")
    );
    console.log(loanAmount, frequency, intrestRate, tenure);
    //console.log(noOfInstallments)

    console.log("noOfInstallments", noOfInstallments);
    let resultArray = [];
    /*const handleClear = () => {
    console.log("clear");
    setLoanAmount("");
    setInstallmentAmount(" ");
    setTenure(" ");
    setFlatIntrest(" ");
    setIntrestRate(" ");
    setActualIntrestRate("");
    setIntrestStartDate("");
    setInstallmentNo("");
  };*/
    //console.log(actualIntrest);

    let firstEmiDate;
    let momentStartDateTemp = moment(momentIntrestStartDate, "DD-MM-yyyy");
    //console.log(momentStartDateTemp)
    //console.log(momentStartDateTemp.date());
    if (momentStartDateTemp.date() < 21) {
      // newDate = moment(momentIntrestStartDate).add(1, "M");
      firstEmiDate = momentStartDateTemp.add(frequency, "M");
      firstEmiDate.set("date", 5);
      console.log("firstEmiDate", firstEmiDate.format("DD-MM-YYYY"));
    } else {
      var m = parseInt(frequency) + 1;
      console.log(typeof m);
      firstEmiDate = momentStartDateTemp.add(m, "M");
      firstEmiDate.set("date", 5);
      console.log("firstEmiDate", firstEmiDate.format("DD-MM-YYYY"));
    }
    let emiDateOne = firstEmiDate.format("DD-MM-YYYY");
    //console.log("emiDateOne",emiDateOne);
    let diffFirst = moment(emiDateOne, "DD-MM-yyyy").diff(
      moment(momentIntrestStartDate, "DD-MM-yyyy"),
      "days"
    );
    noOfdaysArr.push(diffFirst);
    //console.log("diffFirst",diffFirst);

    let obj = {};
    console.log("diffFirst", diffFirst);
    let discountingFactor1 = 1 / (1 + (intrestRate / 100) * (diffFirst / 365));

    console.log("discountingFactor1", discountingFactor1);

    //console.log(discountingFactor1)
    let intrestComponent1 =
      loanAmount * (intrestRate / 100) * (diffFirst / 365);
    intrestComponent1 = intrestComponent1.toFixed(2);

    console.log("intrestComponent1", intrestComponent1);
    obj.InstallmentNo = 1;
    obj.dueDate = emiDateOne;
    obj.noOfDays = diffFirst;
    obj.discountingFactor = discountingFactor1;

    resultArray.push(obj);
    console.log(typeof loanAmount);
    let openingPrincipal1 = loanAmount;

    for (var i = 0; i < noOfInstallments - 1; i++) {
      let date2 = moment(emiDateOne, "DD-MM-yyyy")
        .add(frequency, "M")
        .format("DD-MM-YYYY");
      //console.log(date2)

      let roiPer = intrestRate / 100;
      //console.log(roiPer)
      let diff = moment(date2, "DD-MM-yyyy").diff(
        moment(emiDateOne, "DD-MM-yyyy"),
        "days"
      );
      let discountingFactor =
        (1 / (1 + roiPer * (diff / 365))) * discountingFactor1;
      console.log("discountingFactor", discountingFactor);
      //console.log("discountingFactor",discountingFactor)
      let obj2 = {};
      obj2.installmentNo = i + 2;
      obj2.dueDate = date2;
      obj2.noOfDays = diff;
      noOfdaysArr.push(diff);
      obj2.discountingFactor = discountingFactor;
      resultArray.push(obj2);
      emiDateOne = date2;
      discountingFactor1 = discountingFactor;
      obj2 = {};
    }
    let totalDiscountFactor = 0;
    resultArray &&
      resultArray.map((item, index) => {
        totalDiscountFactor = totalDiscountFactor + item.discountingFactor;
      });
    let finalDiscountingFactor = 0;
    resultArray.map((item, index) => {
      finalDiscountingFactor = item.discountingFactor + finalDiscountingFactor;
    });
    //console.log("finalDiscountingFactor",finalDiscountingFactor);
    console.log("finalDiscountingFactor", finalDiscountingFactor);
    let resultArray2 = [];
    let installmentAmountt = loanAmount / finalDiscountingFactor;
    installmentAmountt = installmentAmountt.toFixed(2);
    //console.log("installmentAmountt", installmentAmountt);
    //console.log(obj);
    const { noOfDays, closingPrincipal } = obj;
    //console.log(noOfDays, closingPrincipal);

    const array2 = resultArray.slice(1);
    //console.log("resultArray", resultArray.slice(1));
    let array3 = [];
    console.log(array2);
    resultArray.forEach(function (v) {
      delete v.discountingFactor;
    });
    //console.log(resultArray);

    let loanAmount1 = loanAmount;
    for (var i = 0; i < resultArray.length; i++) {
      let obj3 = {};
      let openingPrincipal = loanAmount1;
      let openingPrincipal1 = openingPrincipal;
      let installmentNo = i + 1;
      let intrestComponent =
        openingPrincipal1 *
        (intrestRate / 100) *
        (resultArray[i].noOfDays / 365);
      intrestComponent = intrestComponent.toFixed(2);
      let principalAmount = installmentAmountt - intrestComponent;
      principalAmount = principalAmount.toFixed(2);
      let closingPrincipal = openingPrincipal1 - principalAmount;
      closingPrincipal = closingPrincipal.toFixed(2);
      if (closingPrincipal < 0.5) {
        closingPrincipal = 0;
        closingPrincipal = closingPrincipal.toFixed(2);
      }
      //console.log("closingPrincipal",closingPrincipal)
      obj3.installmentNo = installmentNo;
      obj3.dueDate = resultArray[i].dueDate;
      obj3.openingPrincipal = openingPrincipal;
      obj3.intrestComponent = intrestComponent;
      obj3.principalAmount = principalAmount;
      obj3.closingPrincipal = closingPrincipal;
      loanAmount1 = closingPrincipal;

      array3.push(obj3);
      obj3 = {};
    }
    //console.log(resultArray)
    //console.log(array3)
    array3.forEach((element) => {
      element.installmentAmount = installmentAmountt;
    });
    console.log(array3);

    let array4 = [];

    array3.forEach((element) => {
      console.log(element);
      let obj = {};
      let date = moment(element.dueDate).format("DD-MM-YYYY");

      console.log(typeof date);

      obj.date = date;
      obj.amount = installmentAmountt;
      array4.push(obj);
      obj = {};
    });
    let objj = {};
    console.log(moment(momentIntrestStartDate).format("YYYY-MM-DD"));

    const startArray = momentIntrestStartDate.split("-");
    const year = startArray[2];
    const month = startArray[1];
    const day = startArray[0];
    const startDate = month + "-" + day + "-" + year;
    let dates = [];
    let values = [];
    array4.forEach((element) => {
      dates.push(element.date);
    });
    array4.forEach((element) => {
      values.push(element.amount);
    });
    dates.unshift(startDate);
    values.unshift(loanAmount * -1);
    console.log(dates);
    console.log(values);

    console.log(array4);

    const xirr = XIRR(values, dates, 0.1);
    console.log("xirr", xirr);
    let nominal = Math.pow(1 + xirr, 1 / denm) - 1;

    nominal = nominal * denm;
    console.log("nominal", nominal);
    let totalIntrest = 0;
    array3.forEach((element) => {
      totalIntrest = totalIntrest + parseInt(element.intrestComponent);
    });
    console.log("totalIntrest", totalIntrest);
    let flatRate = (totalIntrest / loanAmount) * 100;
    flatRate = (flatRate / noOfInstallments) * denm;

    flatRate = flatRate.toFixed(2);
    console.log("flatRate", flatRate);
    const headerDataIntrestBasis = {
      frequencyy: frequency,
      numberofInstallments: noOfInstallments,
      emii: installmentAmountt,
      loanAmountt: loanAmount,
      xirr: (xirr * 100).toFixed(2),
      flatRate: flatRate,
      nominal: (nominal * 100).toFixed(4),
    };
    console.log("resultArray", resultArray);

    console.log("intrestbasisResult", array3);
    array3.map((item, index) => {
      item.noOfDays = noOfdaysArr[index];
    });
    console.log("intrestbasisResult", array3);

    try {
      dispatch({
        type: CLEAR_DATA,
      });
      dispatch({
        type: SAVE_UNSTRUCTURED_EMI,
        payload: array3,
      });

      dispatch({
        type: SAVE_HEADER_DATA,
        payload: headerDataIntrestBasis,
      });

      dispatch({
        type: GET_DATA_SUCCESS,
        payload: array3,
      });
    } catch (error) {
      dispatch({
        type: GET_STRUCTURED_EMI_FAILURE,
      });
    }
  };
