import { SAVE_UNSTRUCTURED_EMI } from "constants/formConstants";
import { GET_STRUCTURED_EMI_FAILURE } from "constants/formConstants";
import { CLEAR_DATA } from "constants/formConstants";
import { SAVE_HEADER_DATA } from "constants/formConstants";
import moment from "moment";
import { ExitStatus } from "typescript";
import XIRR from "./xirr";

export const testdd =
  (loanAmount, frequency, emi, tenure, intrestStartDate,estimatedIrr) =>
  async (dispatch) => {
    console.log("params",loanAmount, frequency, emi, tenure, intrestStartDate,estimatedIrr)
    let noOfDaysArr=[]
    localStorage.clear("intrestbasisResult");
    const noOfInstallments = Math.ceil(tenure / frequency);

    const getDenm = () => {
      switch (frequency) {
        case "1":
          return 12;
        case "2":
          return 6;
        case "3":
          return 4;
        case "6":
          return 2;
      }
    };
    const denm = getDenm(frequency);
    console.log("demn", denm);

    const momentIntrestStartDate =
      moment(intrestStartDate).format("DD-MM-YYYY");
    let emiBasisResult = [];
    let obj = {};
    console.log("tenure", tenure);
    console.log("frequency", frequency);
    console.log(tenure / frequency);
    console.log(
      "intrestStartDate",
      moment(intrestStartDate).format("YYYY-MM-DD")
    );
    console.log(loanAmount, frequency, emi, tenure, intrestStartDate);
    let dates = [];
    let values = [];
    let resultArray = [];
    let momentStartDateTemp = moment(momentIntrestStartDate, "DD-MM-yyyy");
    obj.installmentNo = 1;
    if (momentStartDateTemp.date() < 21) {
      // newDate = moment(momentIntrestStartDate).add(1, "M");
      var firstEmiDate = momentStartDateTemp.add(frequency, "M");
      firstEmiDate.set("date", 5);
      console.log("firstEmiDate", firstEmiDate.format("DD-MM-YYYY"));
      obj.dueDate = firstEmiDate.format("DD-MM-YYYY");
    } else {
      var m = parseInt(frequency) + 1;
      console.log(typeof m);
      var firstEmiDate = momentStartDateTemp.add(m, "M");
      firstEmiDate.set("date", 5);
      console.log("firstEmiDate", firstEmiDate.format("DD-MM-YYYY"));
      obj.dueDate = firstEmiDate.format("DD-MM-YYYY");
    }
    let emiDateOne = firstEmiDate.format("DD-MM-YYYY");
    //console.log("emiDateOne",emiDateOne);
    let diffFirst = moment(emiDateOne, "DD-MM-yyyy").diff(
      moment(momentIntrestStartDate, "DD-MM-yyyy"),
      "days"
    );
    noOfDaysArr.push(diffFirst);
    console.log(emiDateOne);
    for (var i = 0; i < noOfInstallments - 1; i++) {
      let date2 = moment(emiDateOne, "DD-MM-yyyy")
        .add(frequency, "M")
        .format("DD-MM-YYYY");
      //console.log(date2)

      //console.log(roiPer)
      let diff = moment(date2, "DD-MM-yyyy").diff(
        moment(emiDateOne, "DD-MM-yyyy"),
        "days"
      );
      noOfDaysArr.push(diff);

      //console.log("discountingFactor",discountingFactor)
      let obj2 = {};
      obj2.installmentNo = i + 2;
      obj2.dueDate = date2;
      obj2.noOfDays = diff;

      resultArray.push(obj2);
      emiDateOne = date2;
      obj2 = {};
    }
    obj.noOfDays = diffFirst;
    resultArray.unshift(obj);
    console.log("resultArray", resultArray);
    for (var i = 0; i < noOfInstallments; i++) {
      values.push(parseInt(emi));
    }

    const startArray = momentIntrestStartDate.split("-");
    const year = startArray[2];
    const month = startArray[1];
    const day = startArray[0];
    const startDate = month + "-" + day + "-" + year;
    dates.push(startDate);
    resultArray.forEach((element) => {
      let date = moment(element.dueDate).format("DD-MM-YYYY");

      dates.push(date);
    });

    function naiveRound(num, decimalPlaces = 0) {

      var p = Math.pow(10, decimalPlaces);
  
      return Math.ceil(num * p) / p;
  
  }


    values.unshift(loanAmount * -1);
    console.log(dates);
    console.log(values);
    const xirr = XIRR(values, dates, 0.1);
    console.log("xirr", xirr);
    let nominal = estimatedIrr/100
    console.log("nominal",nominal)
    
    nominal = estimatedIrr;
    const actualNominal=nominal
  
    console.log("nominal",nominal)
    console.log("nominal", nominal);
   // console.log("a",a)
    console.log("demn", denm);

    let objj = {};
    let discountingFactor1 = 1 / (1 + xirr * (diffFirst / 365));
    //console.log(discountingFactor1)
    discountingFactor1 = discountingFactor1.toFixed(2);
    console.log("loanAmount", loanAmount);
    let intrestComponent1 = loanAmount * (nominal/100) * (diffFirst / 365);
    intrestComponent1 = intrestComponent1.toFixed(2);
    objj.InstallmentNo = 1;
    objj.dueDate = emiDateOne;
    objj.noOfDays = diffFirst;
    objj.intrestComponent = intrestComponent1;
    console.log("diffFirst", diffFirst);

    console.log(intrestComponent1);
    //resultArray.push(objj);
    console.log("resultArray", resultArray);
    //console.log(discountingFactor1)
    let loanAmount1 = loanAmount;
    for (i = 0; i < resultArray.length; i++) {
      let obj3 = {};
      let openingPrincipal = loanAmount1;

      let openingPrincipal1 = openingPrincipal;
console.log("estimatedIrr",estimatedIrr)
      console.log("openingPrincipal", openingPrincipal1);
      let installmentNo = i + 1;
      let intrestComponent =
        openingPrincipal1 * (nominal/100) * (resultArray[i].noOfDays / 365);
      intrestComponent = intrestComponent.toFixed(2);
      console.log(xirr);

      console.log(resultArray[i].noOfDays);

      let principalAmount = emi - intrestComponent;
      principalAmount = principalAmount.toFixed(2);
      let closingPrincipal = openingPrincipal1 - principalAmount;
      closingPrincipal = closingPrincipal.toFixed(2);
      /*if (closingPrincipal < 0.5) {
        closingPrincipal = 0;
        closingPrincipal = closingPrincipal.toFixed(2);
      }*/

      //console.log("closingPrincipal",closingPrincipal)


if(i==resultArray.length-1){
  if(closingPrincipal!=0){
const irrResult=nominal
console.log("closingPrincipal",closingPrincipal)
console.log("irr",irrResult)

  }
}

      obj3.installmentNo = installmentNo;
      obj3.dueDate = resultArray[i].dueDate;
      obj3.openingPrincipal = openingPrincipal;
      obj3.intrestComponent = intrestComponent;
      obj3.principalAmount = principalAmount;
      obj3.closingPrincipal = closingPrincipal;
      loanAmount1 = closingPrincipal;

      emiBasisResult.push(obj3);
      obj3 = {};
    }
    let totalDiscountFactor = 0;
    resultArray &&
      resultArray.map((item, index) => {
        totalDiscountFactor = totalDiscountFactor + item.discountingFactor;
        //totalDiscountFactor = totalDiscountFactor.toFixed(2);
      });
    let finalDiscountingFactor = 0;
    emiBasisResult.map((item, index) => {
      finalDiscountingFactor = item.discountingFactor + finalDiscountingFactor;
      //finalDiscountingFactor = finalDiscountingFactor.toFixed(2);
    });
    let totalIntrest = 0;
    emiBasisResult.map((item, index) => {
      totalIntrest = totalIntrest + parseInt(item.intrestComponent);
    });
    totalIntrest = totalIntrest.toFixed(2);
    let flatRate = (totalIntrest / loanAmount) * 100;
    flatRate=(flatRate/noOfInstallments)*denm

    flatRate = flatRate.toFixed(2);
    console.log("flatRate", flatRate);
    const headerData = {
      frequencyy: frequency,
      numberofInstallments: noOfInstallments,
      emii: emi,
      loanAmountt: loanAmount,
      xirrr: xirr,
      nominalll:estimatedIrr,
      xirr: (xirr * 100).toFixed(2),
      flatRate: flatRate,
      nominal:estimatedIrr,
    };
    emiBasisResult.map((item, index) => {
      item.installmentAmount = emi;
    });
    console.log("emibasisResult", emiBasisResult);
    emiBasisResult.map((item, index) => {
      item.noOfDays = noOfDaysArr[index];
    })

    try {

      dispatch({
        type: SAVE_UNSTRUCTURED_EMI,
        payload: emiBasisResult,
      });

dispatch({
  type:SAVE_HEADER_DATA,
  payload:headerData
})


    } catch (error) {
      dispatch({
        type: GET_STRUCTURED_EMI_FAILURE,
      });
    }
  };
  export const cleardata = () => async (dispatch) => {
    dispatch({ type: CLEAR_DATA });
  };