import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { formReducer } from "reducers/formReducer";
import { headerReducer } from "reducers/formReducer";
import { variableEmiReducer } from "reducers/formReducer";
import { variableHaderReducer } from "reducers/formReducer";
import { formReducer2 } from "reducers/formReducer";
import { showTableReducer } from "reducers/formReducer";


const reducer = combineReducers({
  form: formReducer,
  header: headerReducer,
  variableEmi: variableEmiReducer,
  variableHeader: variableHaderReducer,
  tableViewReducer: showTableReducer,
});
let initailState = {};
const middleware = [thunk];
const store = createStore(
  reducer,
  initailState,
  composeWithDevTools(applyMiddleware(...middleware)),

);
export default store;
