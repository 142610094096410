import React from "react";

// components

import VariableEmiTable from "./variableEmiTable";

import CardSettings2 from "components/Cards/CardSettings2";
import CardProfile2 from "components/Cards/CardsProfile2";
import CardProfile from "components/Cards/CardProfile";
import { useSelector } from "react-redux";

export default function Settings2() {
  const { variableHeaderData } = useSelector((state) => state.variableHeader)
  const { loading, variableEmi } = useSelector((state) => state?.variableEmi)

  return (
    <>
      <div className="flex  py-2 flex-wrap">
        <div className="w-full  lg:w-12/12 px-4">
          <CardSettings2 />
        </div>
        {


          variableEmi?.length > 0 &&variableEmi!=null && (
            <div className="result_table" >
              <VariableEmiTable
                loading={loading}
                headerData={variableHeaderData}
                variableEmiResult={variableEmi}
              />

            </div>
          )}

      </div>
    </>
  );
}
