import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import Thingy from "components/Cards/Thingy";
// views without layouts


import Settings from "views/admin/Settings";
import Settings2 from "views/admin/Settings2";
import Tables from "views/admin/Tables";
import Tables2 from "views/admin/Table2";
import CardSettings2 from "components/Cards/CardSettings2";
import IntrestBasisTable from "views/admin/intrestBasisTable";
import EmiBasisTable from "views/admin/emiBasisTable";
import StructuredEmiTable from "views/admin/StructuredEmiTable";
import Tabs from "components/Tabs";
import SettingsTest from "views/admin/SettingsTest";
function App(){
return <BrowserRouter>
    <Switch>
      {/* add routes with layouts */}
      {/* add routes without layouts */}
      <Route path="/" exact component={Tabs} />
      <Route path="/testing" exact component={SettingsTest} />


    
      <Redirect from="*" to="/" />
    </Switch>
  </BrowserRouter>

}
export default App