import React from "react";

// components

import CardSettings from "components/Cards/CardSettings.js";
import CardProfile from "components/Cards/CardProfile.js";
import Doughnut from "components/Charts.js/Doughnut";
import CardSettingsTest from "components/Cards/CardSettingsTest";

export default function SettingsTest() {
  return (
    <>
      <div className="flex py-2 flex-wrap">
        <div className="w-full  lg:w-12/12 px-4">
          <CardSettingsTest />
          
        </div>
       
      
      
        
      </div>
    </>
  );
}
