import { SAVE_HEADER_DATA } from "constants/formConstants";
import { SAVE_VARIABLE_EMI_HEADER } from "constants/formConstants";
import { GET_DATA_REQUEST } from "constants/formConstants";
import { SHOW_TABLE } from "constants/formConstants";
import { TABLE_STATE } from "constants/formConstants";
import { CLEAR_VARIABLE_EMIS } from "constants/formConstants";
import { HIDE_TABLE } from "constants/formConstants";
import { GET_DATA_SUCCESS } from "constants/formConstants";
import { CLEAR_DATA } from "constants/formConstants";
import { SAVE_VARIABLE_EMIS } from "constants/formConstants";
import { GET_UNSTRUCTURED_EMI } from "constants/formConstants";
import { SAVE_UNSTRUCTURED_EMI } from "constants/formConstants";



export const formReducer = (state = { structuredEmi: [] }, action) => {
  switch (action.type) {
    case GET_DATA_REQUEST:
      return {
        loading: true,
        structuredEmi: [],
      };
      case CLEAR_DATA:
        return {
          loading: true,
          structuredEmi: [],
        };
    case SAVE_UNSTRUCTURED_EMI:
      return {
        ...state,
       
        structuredEmi: action.payload,
      };
      case GET_UNSTRUCTURED_EMI:
        return {
          ...state,
          loading: true,
        };
        case GET_DATA_SUCCESS:
          return {
            ...state,
            loading:false,
           
            structuredEmi: action.payload,
          };

      
     
    default:
      return state;
  }
};

export const formReducer2 = (state = { structuredEmi: {} }, action) => {
  switch (action.type) {
    case SAVE_UNSTRUCTURED_EMI:
      return {
        ...state,
        loading: true,
        structuredEmi2: action.payload,
      };
      case GET_UNSTRUCTURED_EMI:
        return {
          ...state,
          loading: true,
        };
        case CLEAR_DATA:
          return {
            ...state,
            structuredEmi:null,
          };
     
    default:
      return state;
  }
};


export const headerReducer = (state = { headerData: {} }, action) => {
  switch (action.type) {
    case SAVE_HEADER_DATA:
      return {
        ...state,
        loading: true,
        headerData: action.payload,
      };
      
      case CLEAR_DATA:
        return {
          ...state,
          headerData:null,
          loading: true,
        };
   
    default:
      return state;
  }
};


export const variableEmiReducer = (state = { variableEmi: {} }, action) => {
  switch (action.type) {
    case SAVE_VARIABLE_EMIS:
      return {
        ...state,
        loading: true,
        variableEmi: action.payload,
      };
     
      case CLEAR_VARIABLE_EMIS:
        return {
          ...state,
          loading: true,
          variableEmi: null,
        };
    default:
      return state;
  }
};

export const variableHaderReducer = (state = { variableHeaderData: {} }, action) => {
  switch (action.type) {
    case SAVE_VARIABLE_EMI_HEADER:
      return {
        ...state,
        loading: true,
        variableHeaderData: action.payload,
      };
      
     
    default:
      return state;
  }
};

export const showTableReducer = (state = { showTable: {} }, action) => {
  switch (action.type) {
    case HIDE_TABLE:
      return {
        ...state,
        showTable:true,
      };
      case SHOW_TABLE:
      return {
        ...state,
        loading: true,
        showTable:false,
      };
      case TABLE_STATE:
        return {
          ...state,
          showTable:action.payload,
         
        };
     
    default:
      return state;
  }
};
