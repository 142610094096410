export const SAVE_UNSTRUCTURED_EMI='SAVE_STRUCTURED_EMI';
export const GET_UNSTRUCTURED_EMI='GET_STRUCTURED_EMI';
export const GET_STRUCTURED_EMI_FAILURE='SAVE_STRUCTURED_EMI_FAILURE';



export const SAVE_HEADER_DATA='SAVE_HEADER_DATA';
export const SAVE_HEADER_DATA_FAILURE='SAVE_HEADER_DATA_FAILURE';


export const SAVE_VARIABLE_EMIS='SAVE_VARAIBLE_EMIS';
export const SAVE_VARIABLE_EMI_HEADER='SAVE_VARIABLE_EMI_HEADER';


export const CLEAR_DATA='CLEAR_DATA';
export const CURRENT_DATA="CURRENT DATA"

export const GET_DATA_REQUEST="GET_DATA_REQUEST"
export const GET_DATA_SUCCESS="GET_DATA_SUCCESS"


export const SHOW_TABLE="SHOW_TABLE"
export const HIDE_TABLE="HIDE_TABLE"

export const TABLE_STATE="TABLE_STATE"

export const CLEAR_VARIABLE_EMIS="CLEAR_VARIABLE_EMIS"