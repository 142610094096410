import React from "react";
import PropTypes from "prop-types";

// components

import TableDropdown from "components/Dropdowns/TableDropdown.js";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

export default function LoanHistoryCardTable2({ color }) {
  const history = useHistory();
  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4  flex-grow flex-1">
              <h3
                className={
                  "font-semibold text-lg " +
                  (color === "light" ? "text-blueGray-700" : "text-white")
                }
              >
                Rohit's Loan History
              </h3>
            </div>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          {/* Projects table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  LOAN ID
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  AMOUNT
                </th>
                <th
                  className={
                    "px-6 align-middle text-center border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Status
                </th>

                <th
                  className={
                    "px-6 align-middle text-center border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Action
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                ></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                  <span
                    className={
                      "ml-3 font-bold " +
                      +(color === "light" ? "text-blueGray-600" : "text-white")
                    }
                  >
                    <p className="text-xs">005</p>
                  </span>
                </th>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  <p className="text-xs"> ₹2,500 </p>
                </td>
                <td className="border-t-0 text-white text-center px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  
                 
                  <p
                    className="bg-orange-500 w-2.5 rounded-full
"
                  >
                    Pending
                  </p>
                </td>
                <td className="border-t-0 rounded-lg px-6 text-center text-black	fill-blue-500 border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                 
                 <Link to="/admin/compareData">
                 <p
                    className="bg-black text-xs text-white rounded-full
"
                  >
                    view
                  </p>
                 </Link>
                  
                </td>
              </tr>
              <tr>
                <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                  <span
                    className={
                      "ml-3 font-bold " +
                      +(color === "light" ? "text-blueGray-600" : "text-white")
                    }
                  >
                    <p className="text-xs"> 004</p>
                  </span>
                </th>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  <p className="text-xs"> ₹18000 </p>
                </td>
                <td className="border-t-0 px-6 text-center align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  <p
                    className="bg-green-500 rounded-full
"
                  >
                    Paid
                  </p>
                </td>
                <td className="border-t-0 rounded-lg px-6 text-center text-black	fill-blue-500 border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                <Link to="/admin/employee/singleloanDetails">
                  <p
                    className="bg-black text-xs text-white rounded-full
"
                  >
                    view
                  </p>
                  </Link>
                </td>
              </tr>
              <tr>
                <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                  <span
                    className={
                      "ml-3 font-bold " +
                      +(color === "light" ? "text-blueGray-600" : "text-white")
                    }
                  >
                    <p className="text-xs"> 003</p>
                  </span>
                </th>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  <p className="text-xs"> ₹31550 </p>
                </td>
                <td className="border-t-0 text-white text-center px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  <p
                    className="bg-red-500 rounded-full
"
                  >
                    UnPaid
                  </p>
                </td>
                <td className="border-t-0 rounded-lg px-6 text-center text-black	fill-blue-500 border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                <Link to="/admin/employee/singleloanDetails">
                  <p
                    className="bg-black text-xs text-white rounded-full
"
                  >
                    view
                  </p>
                  </Link>
                </td>
              </tr>
              <tr>
                <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                  <span
                    className={
                      "ml-3 font-bold " +
                      +(color === "light" ? "text-blueGray-600" : "text-white")
                    }
                  >
                    <p className="text-xs"> 002</p>
                  </span>
                </th>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  <p className="text-xs"> ₹4400 </p>
                </td>
                <td className="border-t-0 rounded-lg px-6 text-center text-black	fill-blue-500 border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  <p
                    className="bg-yellow-500 rounded-full
"
                  >
                    Active
                  </p>
                </td>
                <td className="border-t-0 rounded-lg px-6 text-center text-black	fill-blue-500 border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  
                  <Link to="/admin/employee/singleloanDetails">
                  <p
                    className="bg-black text-xs text-white rounded-full
"
                  >
                    view
                  </p>
                  </Link>
                 
                </td>
              </tr>
             
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

LoanHistoryCardTable2.defaultProps = {
  color: "light",
};

LoanHistoryCardTable2.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};