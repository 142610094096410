import { format } from "date-fns";

export const COLUMNS = [
  {
    Header: "Emi Nos.",
    accessor: "installmentNo",
    Footer: "Emi Nos.",
    sticky: "left",
    minWidth: "200px",
    width: "12.5%",
  },
  {
    Header: "Emi Date",
    accessor: "dueDate",
    Footer: "Emi Date",
    minWidth: "200px",

    width: "12.5%",
    Cell: ({ value }) => {
      return format(new Date(value), "MM/dd/yyyy");
    },
  },
  {
    Header: "Number of Days",
    accessor: "noOfDays",
    Footer: "Number of Days",
    minWidth: "200px",

    width: "12.5%",

  },
  {
    Header: "Opening Balance (Principal)",
    Footer: "Opening Balance (Principal)",
    accessor: "openingPrincipal",
    minWidth: "200px",

    width: "12.5%",

  },
  {
    Header: "Installment (EMI Amount)",
    Footer: "Installment (EMI Amount)",
    accessor: "installmentAmount",
    minWidth: "200px",

    width: "12.5%",

  },
  {
    Header: "Principal (Paid)",
    Footer: "Principal (Paid)",
    accessor: "principalAmount",
    minWidth: "200px",

    width: "12.5%",

  },

  {
    Header: "Interest (Paid)",
    Footer: "Interest (Paid)",
    accessor: "intrestComponent",
    minWidth: "200px",

    width: "12.5%",

  },
  {
    Header: "Closing Balance (Principal)",
    Footer: "Closing Balance (Principal)",
    accessor: "closingPrincipal",
    minWidth: "200px",

    width: "12.5%",

  },
];
