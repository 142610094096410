import { TABLE_STATE } from "constants/formConstants";
import { CLEAR_VARIABLE_EMIS } from "constants/formConstants";
import { SAVE_VARIABLE_EMI_HEADER } from "constants/formConstants";
import { SAVE_VARIABLE_EMIS } from "constants/formConstants";

export const saveVariableEmi = (data) =>async(dispatch)=> {
    
       //console.log(items)
        dispatch
        ({ type:SAVE_VARIABLE_EMIS,
        
            payload:data
        })
    
    

    }

    export const saveVariableEmiHeader = (data) =>async(dispatch)=> {
    
        //console.log(items)
         dispatch
         ({ type:SAVE_VARIABLE_EMI_HEADER,
         
             payload:data
         })
     
     
 
     }


     export const showTableAction = (data) =>async(dispatch)=> {
        console.log(data)
    
        //console.log(items)
         dispatch
         ({ type:TABLE_STATE,
         
             payload:data
         })
            dispatch(showTableAction(false))

     
 
     }

     export const clearData = () =>async(dispatch)=> {
    
        //console.log(items)
         dispatch
         ({ type:CLEAR_VARIABLE_EMIS,
         
             payload:null
         })

     
 
     }