import { emiBasisAction } from "actions/formActionEmiBasis";
import { intrestBasisAction } from "actions/formActionIntrestBasis";
import { testdd } from "actions/testdd";
import moment, { localeData } from "moment";
import React from "react";
import { useState } from "react";

import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import IntrestBasisTable from "views/admin/intrestBasisTable";

// components

export default function CardSettings() {
    const { structuredEmi } = useSelector((state) => state.form);
    const { headerData } = useSelector((state) => state.header);
    console.log(structuredEmi);
    const alert = useAlert();
    const history = useHistory();
    const dispatch = useDispatch();
    const [count, setCount] = useState(0);
    const [frequency, setFrequency] = useState("");
    const [loanAmount, setLoanAmount] = useState("");
    const [emi, setEmi] = useState("");
    const [tenure, setTenure] = useState("");
    const [intrestRate, setIntrestRate] = useState("");
    const [conditionalInput, setConditionalInput] = useState("");
    const [intrestStartDate, setIntrestStartDate] = useState("");
    console.log(conditionalInput);
    let resultArray = [];

    const handleClear = (e) => {
        window.location.reload();
    };
    const handleLoanAmount = (e) => {
        setLoanAmount(e.target.value);
    };
    //console.log(actualIntrestRate);
    const handleRouting = () => { };
    const getFrequencyy = () => {
        switch (frequency) {
            case "1":
                return "Monthly";
            case "3":
                return "Quaterly";
            case "6":
                return "Half Yearly";
            case "2":
                return "Bi Monthly";
        }
    };
    console.log("count", count);
    console.log("conditionalInput", conditionalInput);
    const handleEmiCalculation = () => {
      dispatch(testdd(loanAmount, frequency, emi, tenure, intrestStartDate,intrestRate))
    };

    console.log("emi", emi);
    console.log("intrestRate", intrestRate);

    return (
        <>
            <div className="relative flex mt-2 flex-col min-w-0 break-words w-full shadow-lg rounded-lg bg-blueGray-100 border-0">
                <div className="flex-auto px-4 lg:px-10 py-0 pt-0">
                    <form>
                        <div className="w-full py-4 lg:w-3/12 px-4">
                            <label
                                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                htmlFor="grid-password"
                            >
                                Please Select frequency
                            </label>
                            <select
                                className={
                                    "border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                }
                                onChange={(e) => setFrequency(e.target.value)}
                                aria-label="Default select example"
                            >
                                <option className="text-base border-0 outline-none capitalize bg-white text-black ">
                                    Select below
                                </option>
                                <option
                                    value={1}
                                    className="text-base border-0 outline-none capitalize bg-white text-black "
                                >
                                    Monthly
                                </option>
                                <option
                                    value={2}
                                    className="text-base border-0 outline-none capitalize bg-white text-black "
                                >
                                    Bi Monthly
                                </option>
                                <option
                                    value={3}
                                    className="text-base border-0 outline-none capitalize bg-white text-black "
                                >
                                    Quaterly
                                </option>
                                <option
                                    value={6}
                                    className="text-base border-0 outline-none capitalize bg-white text-black "
                                >
                                    Half Yearly
                                </option>
                            </select>
                        </div>
                        <div className="flex flex-wrap py-4">
                            <div className="w-full lg:w-3/12 px-4">
                                <div className="relative  w-full mb-3">
                                    <label
                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password"
                                    >
                                        Loan Amount
                                    </label>
                                    <input
                                        onChange={(e) => handleLoanAmount(e)}
                                        placeholder="100000"
                                        type="number"
                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="w-full lg:w-3/12 px-4">
                            <div className="relative w-full mb-3">
                                <label
                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                    htmlFor="grid-password"
                                >
                                    Tenure(in months)
                                </label>
                                <input
                                    onChange={(e) => {
                                        setTenure(e.target.value);
                                    }}
                                    type="number"
                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    placeholder="4"
                                />
                            </div>
                        </div>
                       
                        <div className="w-full lg:w-3/12 py-4 px-4">
                            <div className="relative w-full mb-3">
                                <label
                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                    htmlFor="grid-password"
                                >
                                    Emi
                                </label>
                                <input
                                    onChange={(e) => {
                                        setEmi(e.target.value);
                                       
                                    }}
                                    type="number"
                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    placeholder="18%"
                                />
                            </div>
                        </div>
                        <div className="w-full lg:w-3/12 py-4 px-4">
                            <div className="relative w-full mb-3">
                                <label
                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                    htmlFor="grid-password"
                                >
                                    Intrest Rate
                                </label>
                                <input
                                    onChange={(e) => {
                                        setIntrestRate(e.target.value);
                                       
                                    }}
                                    type="number"
                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    placeholder="18%"
                                />
                            </div>
                        </div>

                        <div className="w-full lg:w-3/12 px-4">
                            <div className="relative w-full mb-3">
                                <label
                                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                    htmlFor="grid-password"
                                >
                                    Intrest Start date
                                </label>
                                <input
                                    onChange={(e) => setIntrestStartDate(e.target.value)}
                                    type="date"
                                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    placeholder="20-04-2019"
                                />
                            </div>
                        </div>
                    </form>
                    <button
                        onClick={(e) => handleClear(e)}
                        className=" px-4 space-x-2  bg-clear-500 mt-2  text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 rounded-full"
                    >
                        Clear
                    </button>

                    <button
                        onClick={handleEmiCalculation}
                        className=" mt-2 bg-emi-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 rounded-full"
                    >
                        Emi pattern
                    </button>
                </div>
            </div>
            {structuredEmi.length > 0 && headerData && (
                <div>
                    <IntrestBasisTable
                        className="mt-4"
                        headerData={headerData}
                        dataa={structuredEmi}
                    />
                </div>
            )}
        </>
    );
}