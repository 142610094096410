import { format } from "date-fns";

export const VAR_COLUMNS = [
    {
      Header: "Emi Nos.",
      accessor: "installmentNo",
      Footer: "Emi Nos.",
    },
    {
      Header: "Emi Date",
      accessor: "dueDate",
      Footer: "Emi Date",
      Cell: ({ value }) => {
        return format(new Date(value), 'dd/MM/yyyy')
      }
    },
    {
      Header: "Number of Days",
      accessor: "noOfDays",
      Footer: "Number of Days",
    },
    {
      Header: "Opening Balance (Principal)",
      Footer: "Opening Balance (Principal)",
      accessor: "openingPrincipal",
  
    },
    {
      Header: "Installment (EMI Amount)",
      Footer: "Installment (EMI Amount)",
      accessor: "installmentAmount",
  
    },
    {
      Header: "Principal (Paid)",
      Footer: "Principal (Paid)",
      accessor: "principalComponent",
    },
  
    {
      Header: "Interest (Paid)",
      Footer: "Interest (Paid)",
      accessor: "intrestComponent",
    },
    {
      Header: "Closing Balance (Principal)",
      Footer: "Closing Balance (Principal)",
      accessor: "closingPrincipal",
    },
   
  ];