import { COLUMNS } from "components/constants/columns";
import GlobalFilter from "components/GlobalFilter";
import moment, { localeData } from "moment";
import { forwardRef } from "react";

import React from "react";
import { useMemo } from "react";
import { useTable } from "react-table";
import { xirr, CashFlow } from "@webcarrot/xirr";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { cleardata } from "actions/formActionEmiBasis";
import { useState } from "react";
import { intrestBasisAction } from "actions/formActionIntrestBasis";

const IntrestBasisTable = ({ dataa, headerData }) => {
  const dispatch = useDispatch();
  const {
    frequencyy,
    numberofInstallments,
    emii,
    loanAmountt,
    nominalll,
    xirrr,
    xirr,
    flatRate,
    nominal,
  } = headerData;
  const MOCK_DATA = dataa;

  //console.log(emiArray2)
  console.log(MOCK_DATA);

  //console.log(totalIntrest)
  //console.log(MOCK_DATA)

  const ref = React.createRef();
  const color = "light";
  //console.log(MOCK_DATA)

  const getFrequencyy = () => {
    switch (frequencyy) {
      case "1":
        return "Monthly";
      case "3":
        return "Quaterly";
      case "6":
        return "Half Yearly";
      case "2":
        return "Bi Monthly";
    }
  };

  console.log("mockdata", MOCK_DATA);

  const columns = useMemo(() => COLUMNS, []);
  const data =   dataa;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  });

  return (
    <>
      <div
        className={
          "relative flex mt-4 flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-theme text-bold text-white")
        }
      >
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h3
                className={
                  "font-semibold text-lg " +
                  (color === "light" ? "text-blueGray-700" : "text-white")
                }
              >
                Amortization table{" "}
              </h3>
            </div>
          </div>
        </div>

        <div className="block w-full py-4 overflow-x-auto">
          {/* Projects table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Frequency
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  IRR
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Emi Amount
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  EMi NoS
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Loan Amount
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  FLAT Rate
                </th>

                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                ></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                  <span
                    className={
                      "ml-0 font-bold px-2 " +
                      +(color === "light" ? "text-blueGray-600" : "text-white")
                    }
                  >
                    {getFrequencyy()}
                  </span>
                </th>
                <td className="border-t-0 px-4 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  {parseFloat(nominal).toFixed(2)}%
                </td>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  {emii}
                </td>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  {numberofInstallments}
                </td>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  {loanAmountt}
                </td>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  {parseFloat(flatRate).toFixed(2)}%
                </td>

                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right"></td>
              </tr>
            </tbody>
          </table>
          {dataa && (
            <table
              {...getTableProps()}
              className="items-center mt-4 w-full bg-transparent border-collapse"
            >
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr
                    {...headerGroup.getHeaderGroupProps()}
                    className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                  >
                    {headerGroup.headers.map((column) => (
                      <th
                        className={
                          "px-6 font-extrabol align-middle border border-solid py-2 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap  text-right " +
                          (color === "light"
                            ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                            : "bg-theme font-extrabold	 text-white")
                        }
                        {...column.getHeaderProps({
                          style: {
                            minWidth: column.minWidth,
                            width: column.width,
                          },
                        })}
                      >
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr
                      className="border-t-0 px-6 text-left border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                      {...row.getRowProps()}
                    >
                      {row.cells.map((cell) => {
                        return (
                          <td
                            className="px-6 py-4 text-black font-bold align-middle border-2 whitespace-nowrap text-right"
                            {...cell.getCellProps()}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
              <tfoot id="myAnchor">
                {footerGroups.map((footerGroup) => (
                  <tr
                    className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                    {...footerGroup.getFooterGroupProps()}
                  >
                    {footerGroup.headers.map((column) => (
                      <td
                        className={
                          "px-6 font-extrabol align-middle border border-solid py-2 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap  text-right " +
                          (color === "light"
                            ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                            : "bg-theme font-extrabold	 text-white")
                        }
                        {...column.getFooterProps()}
                      >
                        {column.render("Footer")}
                      </td>
                    ))}
                  </tr>
                  
                ))}

               
              </tfoot>
            </table>
          )}
        </div>
      </div>
    </>
  );
};

export default IntrestBasisTable;
